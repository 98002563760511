import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  colors: {
    primary: "#f0e4d8",
    secondary: "#FFF8E1",
    tertiary: "#6C9BCF",
    quaternary: "#b09dcd",
  },
};
